/**
 * First draft of Select props type, still needs work.
 */

import type * as Immutable from "immutable";
import { type Key, type ReactNode } from "react";

export type SelectOptionType = "toggle" | "sub-header" | "divider" | "link";
export type SelectSize = "x-small" | "small" | "medium";

export type SelectValue = number | string;

export interface SelectOption {
  selected?: string;
  value: SelectValue;
  label?: ReactNode;
  icon?: string | (() => JSX.Element);
  type?: SelectOptionType;
  subMenu?: SelectOption[];
  customSubMenu?: ReactNode;
  customSelectedElement?: unknown;
  disabled?: boolean;
  description?: string;
  tooltip?: ReactNode;
  key?: Key;
  children?: ReactNode;
  props?: unknown;
}

export interface SelectProps {
  className?: string | null;
  customButton?: string | null;
  customClassName?: string | null;
  customParentValue?: string | null;
  customSelectedValue?: string | object | null;
  disabled?: boolean;
  forceBottomAligned?: boolean;
  forceRightAligned?: boolean;
  isDark?: boolean;
  isInvalid?: boolean;
  isSmall?: boolean;
  noBorder?: boolean;

  // TODO: Fix me eventually
  // The type is actually `(value: string | number) => void`
  // But in order to define it this way we need to change the way it's used
  // Ideally the value should be only of `string` type
  onChange: (...args: unknown[]) => void;

  onToggleChange?: (...args: unknown[]) => void;
  options: readonly SelectOption[];
  placeholder?: ReactNode;
  placeholderIcon?: string | null;
  placeholderIconColor?: string | null;
  plainDropdown?: boolean;
  resultsMaxHeight?: string | number;
  showIconAsSelected?: boolean | null;
  showIconBesideSelected?: boolean;
  onlyShowIconBesideSelected?: boolean;
  size?: SelectSize;
  subMenuValue?: SelectValue;
  title?: string;
  toggleValues?: Immutable.Map<string | number, boolean>;
  useRadioButtons?: boolean;
  value?: SelectValue;
  warningMessage?: string;
  label?: string;
}
