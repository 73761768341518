/**
 * @deprecated
 */
export type FeatureFlag =
  | "actionIntegrations"
  | "adaGlassIntercom"
  | "adaGlassNuance"
  | "adaGlassOracle"
  | "adaGlassSalesforce"
  | "adaGlassZendesk"
  | "adaGlassZendeskChatterAuth"
  | "adaGlassZendeskMessaging"
  | "afmAbTesting"
  | "afmBusinessEvents"
  | "afmGoals"
  | "afmHandoffEvents"
  | "afmProactiveMessaging"
  | "afmProactiveSms"
  | "authentication"
  | "autolaunchTemp"
  | "automatedResolutionReport"
  | "automatedResolutionFeedback"
  | "builderBot"
  | "channelSmooch"
  | "chatUiExtra"
  | "conditionals"
  | "conversationViewSummaries"
  | "conversationsTopics"
  | "countHttpRequestAsHandoff"
  | "csatProPostChat"
  | "csatV2"
  | "customHandoffEventBlock"
  | "customJavascriptEventBlock"
  | "dataApi"
  | "dataApiTrial"
  | "dndV2"
  | "donatedBot"
  | "dynamicAdaApps"
  | "emailSubjectHandoffField"
  | "endChat"
  | "enforceMfa"
  | "experimentVoice"
  | "forcedQuickReplies"
  | "handoff1"
  | "integrationBlockSalesforce"
  | "intros"
  | "labellingResolutionTemp"
  | "nerExperiment"
  | "noBranding"
  | "nuanceLiveAgent"
  | "operationHours"
  | "passwordExpiry"
  | "personalization"
  | "predictiveSuggestions"
  | "pushNotifications"
  | "queueBotBlock"
  | "quickRepliesBlock"
  | "rawJsonModal"
  | "requestBlock"
  | "salesforceFileTransfer"
  | "satisfaction"
  | "scheduleSettings"
  | "segmentation"
  | "selection"
  | "selfServeChannels"
  | "smartSearchConfluence"
  | "smartSearchPinterest"
  | "ssoDashboard"
  | "suncoMultiBotRouting"
  | "tempMatchClientBranding"
  | "twilioDirectSms"
  | "unansweredQuestionGrouping"
  | "useMockDataAnalytics"
  | "variableChangeMetaMessages"
  | "variableSetBlock"
  | "webhookChannelApi"
  | "wflwAdvancedLob"
  | "wflwListOfParentAnswers"
  | "widgetBlock"
  | "zendeskAgentWorkspace"
  | "zendeskDepartmentMatching"
  | "zendeskTicketing"
  | "buGenerativeAnswersTemp"
  | "buKnowledgeApiTemp"
  | "voiceInHouseTwilioTemp"
  | "generatedTopics";
