import { Icon } from "@adasupport/byron";
import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Checkbox } from "components/Common/Checkbox";
import { selectClient, useClient } from "services/client";
import { keys } from "services/helpers";

import { DevToolbarClientFeatures } from "./DevToolbarClientFeatures";
import * as S from "./styles";
import "./style.scss";

const BLOCK_NAME = "DevToolbar";

function DevToolbarContent() {
  const { client, updateClient, saveClient } = useClient();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={classNames(BLOCK_NAME, {
        [`${BLOCK_NAME}--expanded`]: isExpanded,
      })}
    >
      <button
        type="button"
        className={`${BLOCK_NAME}__toggle`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        dev tools
        <span className={`${BLOCK_NAME}__toggle-icon`}>
          <Icon.CloseSmall />
        </span>
      </button>

      <div className={`${BLOCK_NAME}__content`}>
        <DevToolbarClientFeatures />

        <hr />

        <S.Title>Other flags (boolean attributes)</S.Title>

        {keys(client).map((key) => {
          const value = client[key];

          if (typeof value !== "boolean") {
            return null;
          }

          return (
            <S.CheckboxContainer key={key}>
              <Checkbox
                labelSide="right"
                size="small"
                label={key}
                handleToggle={(val) => {
                  updateClient({ [key]: val });
                  saveClient();
                }}
                checked={value}
                theme="dark"
              />
            </S.CheckboxContainer>
          );
        })}
      </div>

      <div className={`${BLOCK_NAME}__shadow-thing`} />
    </div>
  );
}

export function DevToolbar() {
  const client = useSelector(selectClient);

  if (!client) {
    return null;
  }

  return <DevToolbarContent />;
}
